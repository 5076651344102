@import './assets/scss/core/variables';

$antd_overflow: #f0f0f0;

.ant-form-item-label > label,
.ant-menu,
.ant-menu-light,
.ant-modal-title,
.participant-help-text {
  color: $textColor;
}

/*** Forms ***/

.ant-form-item-label > label {
  color: $textColor !important;
  font-size: 1.2em;
}

.ant-form-item .ant-form-item-label {
  display: flex;
}

.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
  &::before {
    content: '';
    margin-inline-end: 0;
  }

  &::after {
    display: inline-block;
    margin-inline-start: 0rem;
    color: $secondaryColor;
    font-size: 0.7rem;
    padding-bottom: 0.4rem;
    line-height: 1;
    content: '*';
    visibility: inherit !important;
  }
}

.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 0 0;
  margin-bottom: 0.2em;
}

.ant-space-compact {
  .ant-input {
    font-size: 1em;
  }
}

.ant-input-number-group-wrapper {
  display: flex;
}

.ant-input-prefix {
  svg {
    color: #aeaeae;
  }
}

.ant-input-affix-wrapper .ant-input-prefix {
  margin-inline-end: 0.7em;
}

.ant-input-suffix,
.ant-input-prefix {
  font-size: 1em;
}

.ant-input-affix-wrapper,
.ant-input,
.ant-input-number,
.ant-picker {
  &:focus,
  &-focused,
  &:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: $secondaryColor;
  }

  &:focus,
  &-focused {
    box-shadow: 0 0 2px $secondaryColor;
  }

  &.ant-input-disabled:hover {
    border-color: inherit;
  }
}

.ant-select-single {
  height: auto !important;

  &:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: 100%;
  }

  & .ant-select-selector .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ) {
  &.ant-select-focused .ant-select-selector {
    box-shadow: 0 0 2px $secondaryColor;
  }
  &.ant-select-open .ant-select-selector,
  &.ant-select-focused .ant-select-selector,
  &:hover .ant-select-selector {
    border-color: $secondaryColor;
  }
}

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: $secondaryColorLighter;
}

.ant-select-dropdown .ant-select-item-option-grouped {
  padding-inline-start: 0.8em;
}

.ant-picker-dropdown {
  .ant-picker-cell-in-view {
    &.ant-picker-cell-today .ant-picker-cell-inner::before {
      border-color: $secondaryColor;
    }

    &.ant-picker-cell-selected .ant-picker-cell-inner {
      background: $secondaryColorLighter;
      color: #000000;
    }
  }

  .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell-selected
    .ant-picker-time-panel-cell-inner {
    background: $secondaryColorLighter;
  }
}

/*** Buttons ***/

.ant-btn-text {
  padding: 0 !important;
  color: $defaultTextColor;
}

.ant-btn-link {
  span {
    text-decoration: underline;
  }
}

.ant-btn:not(.ant-btn-text).ant-btn-sm {
  height: fit-content;
  padding: 0.25em 0.75em;
  font-size: 0.8rem;
  border-radius: 6px;
}

.ant-btn-group .ant-btn:not(.ant-btn-text).ant-btn-sm {
  border-radius: 0;
}

.ant-btn-group .ant-btn:not(.ant-btn-text).ant-btn-sm:first-child {
  border-radius: 6px 0 0 6px;
}

.ant-btn-group .ant-btn:not(.ant-btn-text).ant-btn-sm:last-child {
  border-radius: 0 6px 6px 0;
}

.ant-btn:not(.ant-btn-text) {
  padding: 0.5em 1em;
}

.ant-space-compact {
  .ant-input {
    padding: 4px 15px;
  }

  .ant-btn {
    height: 32px;
  }
}

.ant-btn {
  height: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;

  span {
    font-size: 1.1em;
  }
}

.ant-btn.ant-btn-primary:not(:disabled) {
  background-color: $secondaryColor;
  color: $secondaryColorText;
  border: solid $secondaryColor 1px;

  &:hover {
    background-color: $secondaryColorLighten !important;
    color: $secondaryColorText;
  }

  &.ant-btn-dangerous {
    background-color: $errorColor;
    color: $secondaryColorText;
    border: solid $errorColor 1px;

    &:hover {
      background-color: lighten($errorColor, 10%) !important;
    }
  }
}

.ant-btn-primary:disabled {
  border: solid 1px $antdGreyDefault;
  color: $antdGreyDefault;
}

.ant-btn.btn-secondary {
  background-color: $primaryColor;
  color: $primaryColorText !important;
  border: none !important;

  &:hover {
    background-color: $primaryColorLighter !important;
    color: $primaryColorText !important;
  }
}

.ant-modal .ant-modal-content .ant-modal-footer .ant-btn-default,
.ant-btn-default {
  border: $secondaryColor solid 1px;
  color: $secondaryColor !important;

  &:hover {
    background-color: $secondaryColorLighter;
  }
}

.ant-btn.ant-btn-circle {
  width: 2.5rem !important;
  height: 2.5rem !important;
  padding: 0 !important;
  min-width: none !important;
}

/*** Modals ***/
.ant-modal .ant-modal-content {
  border-radius: 0.5em;
  padding: 0;
  .ant-modal-confirm-title {
    color: $textColor;
    padding: 0.5rem 2rem;
    border-bottom: 1px solid $lightgrey;
    margin-bottom: 0px;
    font-size: 1.4rem;
  }
  .ant-modal-header {
    padding: 0.5rem 2rem;
    border-bottom: 1px solid $lightgrey;
    margin-bottom: 0px;

    .ant-modal-title {
      font-size: 1.4rem;
    }
  }

  .ant-modal-confirm-paragraph {
    .ant-modal-confirm-content {
      padding: 0 2rem;
    }
    max-width: 100%;
  }

  .ant-modal-close {
    top: 14px;
  }

  .ant-modal-confirm-body {
    padding: 0;
  }
  .ant-modal-body {
    padding: 0;

    > *:not(.ant-modal-confirm-body-wrapper) {
      padding: 0 2rem;
    }
    p {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-top: 1em;
      margin-bottom: 1em;
    }

    .unwrapped-text {
      -webkit-line-clamp: 100;
    }

    #basic > .ant-form-item {
      margin-top: 0;
      margin-bottom: 0;
    }

    .ant-modal-confirm-body-wrapper,
    .ant-modal-confirm-content {
      margin-top: 1em;
      margin-bottom: 1em;
    }

    .ant-modal-confirm-body-wrapper,
    .ant-modal-confirm-paragraph {
      row-gap: 0 !important;
    }

    .ant-modal-confirm-body-wrapper,
    .ant-btn-primary:focus-visible {
      outline: none;
    }
  }

  .ant-modal-footer,
  .ant-modal-confirm-btns {
    border-top: 1px solid $lightgrey;
    padding: 0.7rem 2rem;
    margin-top: 0rem;
    display: flex;
    justify-content: end;
    align-items: center;

    .ant-btn {
      height: fit-content;
      padding: 0.5em 2em;
      font-size: 0.8rem;

      &.ant-btn-primary {
        margin-inline-start: 8px !important;
      }
    }
  }
}

.ant-modal-header .ant-modal-title {
  color: $textColor;
}

.ant-modal .ant-modal-body {
  font-size: $defaultFontSize * 1.07;
}

/*** Tabs ***/
.ant-tabs {
  .ant-tabs-nav {
    &::before {
      content: none;
    }

    .ant-tabs-ink-bar {
      height: 3px;
      background-color: $secondaryColor;
    }
  }

  .ant-tabs-tab {
    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: $secondaryColor;
    }

    .ant-tabs-tab-btn {
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $textColor;
    }
  }
}

/*** Menu ***/

.ant-menu-item {
  width: 100% !important;
  border-radius: 0 !important;
  padding-left: 1rem !important;
  margin: 0.3em 0 !important;
  font-size: $defaultFontSize * 1.07;
}

.ant-menu-item-disabled {
  cursor: not-allowed !important;
  label {
    cursor: not-allowed !important;
    color: $antdGreyDefault !important;
  }
}

.ant-menu-submenu {
  padding-left: 1rem !important;
  background: none !important;

  .ant-menu-submenu-title {
    padding-left: 0 !important;
    margin: 0 !important;

    &:hover {
      background: none !important;
    }
  }

  .ant-menu-item {
    background: none !important;
    &:hover {
      background: $antd_overflow !important;
    }
  }
}

.ant-menu-item-selected {
  background-color: $darkGrey !important;
  margin: 0.3em 0;
}

.ant-menu .ant-menu-submenu-arrow {
  color: $textColor !important;
}

.ant-menu-item {
  border-right: solid 2px transparent;

  &.ant-menu-item-selected {
    border-color: $secondaryColor;

    .ant-menu-title-content {
      color: $secondaryColor;
    }

    .ant-menu-item-icon {
      color: $secondaryColor;
    }
  }

  .ant-menu-item-icon {
    font-size: $defaultFontSize * 1.2 !important;
  }
}

.ant-menu-light.ant-menu-root.ant-menu-inline {
  border-inline-end: 0 !important;
}

.ant-menu-light:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):active {
  background-color: $secondaryColorLighter;
}

.ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline,
.ant-menu-light > .ant-menu.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background: unset;
}

/*** Tag ***/
.ant-tag.ant-tag-blue {
  padding: 0.25rem 0.5rem;
  color: $secondaryColor;
  border: thin solid $secondaryColor;
  background-color: $secondaryColorLighter;
  border-radius: 6px;

  .ant-tag-close-icon {
    color: $secondaryColor;
  }
}

/*** Table ***/

.ant-table-content {
  overflow: auto;
}

.ant-table-cell {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 10em;
  overflow: hidden;
  font-size: $defaultFontSize * 1.07;
}

.ant-table-column-sorters {
  .ant-table-column-sorter-up,
  .ant-table-column-sorter-down {
    &.active {
      color: $secondaryColor !important;
    }
  }
}

.ant-table-tbody > tr > td {
  border-top: none !important;
  padding: 0 0.5em !important;
  height: 3em !important;
}

.ant-table-thead {
  color: $defaultTextColor !important;
}

// .ant-table-tbody > tr:last-child > td {
//   border-bottom: none !important;
// }

.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  width: 0;
}

.ant-table-wrapper .ant-table-thead > tr > th {
  padding: 0.2rem 0.4rem !important;
  border-bottom: 0;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  background-color: $extraLightgrey !important;
}

// .ant-table-wrapper .ant-table-tbody > tr > td {
//   border-bottom: none !important;
// }

.ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child {
  border-start-end-radius: 0 !important;
}

.ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
  border-start-start-radius: 0 !important;
}

@media screen and (max-width: $sm) {
  .ant-table-wrapper table {
    border-collapse: separate;
    border-spacing: 0;
    colgroup > col {
      width: unset !important;
    }
  }

  .ant-table-wrapper {
    .ant-table {
      &-thead {
        display: none;
      }

      &-tbody > tr {
        border: solid 1px $lightgrey;
        display: flex;
        flex-wrap: wrap;
        box-shadow: 5px 5px 13px -7px #000000;
        margin-top: 2rem;
        padding: 0.4rem !important;
        // border-top-left-radius: 6px;
        // border-top-right-radius: 6px;
        // border-bottom-left-radius: 6px;
        // border-bottom-right-radius: 6px;

        &:first-child {
          margin-top: 1rem;
        }
        > th,
        > td {
          display: block;
          border: none;
          padding: 0 1rem;
          width: 100%;
          text-align: center;
          text-align: -webkit-center;

          &:first-child {
            font-weight: 600;
            text-align: left;
            // border-top-left-radius: 6px;
            // border-top-right-radius: 6px;
          }
          &:last-child {
            // border-bottom-left-radius: 6px;
            // border-bottom-right-radius: 6px;
          }
        }
      }

      .ant-table-cell {
        max-width: 100%;
        padding: 0.3rem !important;

        .action-container {
          justify-content: flex-end;
          gap: 1rem;
        }
      }
    }
  }
}

@media (max-width: $md) {
  .ant-table-placeholder {
    display: none !important;
  }
}

/*** Divider ***/

.ant-divider {
  margin: 1rem 0 1rem 0;
}

/*** Dropdown ***/

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: $defaultFontSize * 1.07;
  padding: 0.5em;
  color: $defaultTextColor;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected {
  background-color: $secondaryColorLighter;
  color: unset;

  &:hover {
    background-color: $secondaryColorLighter;
  }
}

.ant-dropdown-menu-item-disabled {
  cursor: not-allowed !important;

  label {
    cursor: not-allowed !important;
    color: $antdGreyDefault !important;
  }

  .ant-dropdown-menu-item-icon {
    color: $antdGreyDefault !important;
  }
}

.ant-dropdown-menu-submenu-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/*** Upload ***/
.ant-upload-wrapper .ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: $secondaryColor;
}

@media screen and (max-width: $sm) {
  .ant-divider {
    margin: 1rem 0 1rem 0;
  }
}

/*** Drawer ***/
.ant-drawer .ant-drawer-title {
  color: unset;

  .ant-drawer-body {
    padding: 1.5em;
  }
}

.ant-drawer-header,
.ant-drawer-body {
  border-left: 1px solid $antd_overflow;
  background-color: #fff;

  .ChatListComponent {
    background-color: #fff;
    * :not(.chat_item_avatar_container *, .chat_item_unread_messages_count) {
      background-color: #ffffff;
      .chat_item:hover * :not(.chat_item_unread_messages_count) {
        background-color: $extraLightgrey;
      }
      .chat_item:hover .chat_item_avatar_container {
        background-color: $extraLightgrey;
      }
    }
    .chat_item:hover {
      background-color: $extraLightgrey;
    }
  }

  .DrivesMenu {
    background-color: $extraLightgrey !important;
    * :not(.ant-tree *) {
      background-color: $extraLightgrey !important;
    }
  }
}

.ant-drawer-mask {
  background-color: unset !important;
}

.ant-drawer-content-wrapper {
  width: 22rem;
  box-shadow: unset !important;
}

.ant-drawer-content {
  background: unset !important;
}

@media screen and (max-width: $sm) {
  .ant-drawer-content-wrapper {
    max-width: 19.5rem !important;
  }
}

/** Pagination **/
.ant-pagination {
  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    border: $secondaryColor;
    a {
      color: $secondaryColor;
    }
  }

  .anticon-double-left,
  .anticon-double-right {
    color: $secondaryColor !important;
  }

  .ant-pagination-item {
    margin-inline-end: 0;
    width: 1rem;
    border: $secondaryColor;
  }

  .ant-pagination-item-active {
    margin-top: 1px;
    border: 1px solid $secondaryColor;
    a {
      color: $secondaryColor;
    }
    &:hover {
      border: 1px solid $secondaryColor;
      a {
        color: $secondaryColor;
      }
    }
  }

  .ant-pagination-prev {
    margin-inline-end: 0;
  }
}

/** Tooltip **/

.ant-tooltip {
  z-index: 100000;
}

.ant-tooltip {
  max-width: unset;
}

@media (max-width: $md) {
  .ant-tooltip {
    display: none;
  }
}

/** Ant switch**/

.ant-switch-inner {
  border: none !important;
}

/** Ant Tree **/

.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .ant-tree-title {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: $defaultFontSize * 1.07;
  }
}

.ant-tree.ant-tree-directory {
  .ant-tree-treenode {
    padding: 0.6em;

    .ant-tree-node-content-wrapper {
      display: flex;
      justify-content: flex-start;
      gap: 0.3em;
      align-items: center;
    }
  }
}

.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
  background-color: $darkGrey;
  bottom: 0;
}

.ant-tree.ant-tree-directory
  .ant-tree-treenode
  .ant-tree-node-content-wrapper.ant-tree-node-selected,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
  color: $textColor !important;
}

.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle,
.ant-tree .ant-tree-checkbox + span .ant-tree-iconEle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-tree .ant-tree-switcher {
  width: 1em;
}

.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle,
.ant-tree .ant-tree-checkbox + span .ant-tree-iconEle {
  font-size: 1.4em;
}

.ant-tree .ant-tree-indent-unit {
  width: 1em;
}

.ant-tree.ant-tree-directory .ant-tree-treenode:before {
  bottom: 0;
}

/** Switch **/

.ant-switch {
  background-color: $tertiaryColor;
  &.ant-switch-checked {
    background-color: $successColor !important;
  }
}
:where(.ant-switch:hover:not(.ant-switch-disabled)) {
  background: $tertiaryColor !important;
}

/** Ant popover**/

.ant-popover {
  max-width: 20rem;
  border: solid 1px $lightgrey;
  border-radius: 8px;
}

.ant-popover-arrow::after {
  border: solid 1px $lightgrey;
}

.ant-popover-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.ant-popover-title {
  font-weight: bold !important;
}

/** Alert **/

.ant-alert-error {
  border: 3px solid #e10000;
}

/** Collaspe **/

.ant-collapse-header-text {
  color: $primaryColor;
}

.ant-collapse-expand-icon {
  color: $primaryColor;
}

.ant-collapse-extra {
  color: $primaryColor;
}

/** Spin **/

.ant-spin-dot-item {
  background-color: $secondaryColor !important;
}

/** Radio **/

.ant-radio-wrapper {
  &:hover {
    .ant-radio-inner {
      border-color: $secondaryColor !important;
    }
    .ant-radio-checked .ant-radio-inner {
      border-color: $secondaryColor !important;
      background-color: $secondaryColor !important;
    }
  }

  .ant-radio {
    &.ant-radio-checked {
      .ant-radio-inner {
        background-color: $secondaryColor !important;
        border-color: $secondaryColor !important;
      }
      &::after {
        border-color: $secondaryColor !important;
      }
    }
  }
}

.ant-radio-button-wrapper {
  &:hover {
    color: $secondaryColor;
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      color: $secondaryColor;
      border-color: $secondaryColor;

      &:first-child {
        border-color: $secondaryColor;
      }

      &::before {
        background-color: $secondaryColor;
      }
    }
  }

  &-checked:not(.ant-radio-button-wrapper-disabled) {
    color: $secondaryColor;
    border-color: $secondaryColor;

    &:first-child {
      border-color: $secondaryColor;
    }

    &::before {
      background-color: $secondaryColor;
    }
  }
}

/** ant-breadcrumb **/

.ant-breadcrumb {
  line-height: normal;
  min-width: 0;
  flex: auto;
  color: $textColor;

  ol {
    flex-wrap: nowrap;
    max-width: 100%;

    li {
      flex-grow: 1;
      flex-shrink: 10;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      min-width: 0;

      span {
        width: 100%;
        flex: 1;
        max-width: fit-content;

        a {
          min-width: 0;
          max-width: fit-content;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: $primaryColor !important;
        }
      }

      &:first-child {
        flex-shrink: 0;
        flex-grow: 0;
      }

      &:last-child {
        flex-grow: 100;
        flex-shrink: 1;
      }
    }
  }

  .anticon-down {
    flex: 0;
    min-width: 1.5rem !important;
    svg {
      fill: $primaryColor !important;
    }
  }

  .ant-breadcrumb-link {
    display: flex;
    align-items: center;
    font-size: 1.2em;
    flex-shrink: 10;
    min-width: 0;
    flex-grow: 1;

    &:hover > * {
      text-decoration: underline;
      background-color: transparent !important;
    }
  }

  .ant-breadcrumb-overlay-link {
    &:hover {
      background-color: transparent !important;
    }
  }

  .ant-dropdown-trigger {
    display: flex;
    align-items: center;
  }

  .ant-breadcrumb-separator {
    font-size: 1.1em;
    color: $primaryColor;
    flex-shrink: 0;
    flex-grow: 0;
  }
}

/** ant-notification / Toaster **/

.ant-notification-notice-content {
  padding-right: 1.5em;
}

/** Floating button **/

.ant-float-btn-primary {
  inset-inline-end: 3.5em !important;
  inset-block-end: 3.5em !important;
  .ant-float-btn-body {
    background-color: $secondaryColor;

    .ant-float-btn-content {
      margin-top: 2px;
    }

    .ant-float-btn-icon {
      width: unset !important;
    }
  }
}

.float-btn-disabled {
  cursor: not-allowed;
  .ant-float-btn-body {
    background-color: $lightgrey !important;
  }
}

.ant-float-btn-circle {
  height: 3.5em;
  width: 3.5em;
}

/** antd tour **/

.ant-tour-buttons {
  display: flex;
}

.ant-tour-placeholder-animated {
  background-color: red !important;
}
