.chat_icon {
  text-transform: capitalize;
  font-size: 0.9em;
  width: 2.2em;
  height: 2.2em;
  border-radius: 50%;
  color: #ffffff;
  background-color: #224160;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
