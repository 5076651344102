@import '../../assets/scss/core/variables';

.Drives {
  main {
    .content {
      aside {
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
      }

      article {
        padding: 0;

        @media (max-width: 992px) {
          padding: 0 !important;
        }
      }
    }
  }

  .ant-menu {
    margin-bottom: 0.2em;
  }

  &-folder-actions {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4em;
    color: $textColor;
    cursor: pointer;
    margin-bottom: 0;

    &:hover {
      text-decoration: underline;
    }
  }

  &-folder-actions-mobile {
    display: none;
  }

  &-main-container {
    display: flex;
    flex-direction: column;
    height: 95%;
  }

  &-breadcrumb-container {
    display: flex;
    align-items: center;
    gap: 0.5em;
    height: 1.5rem;
    min-width: 0;
    flex: 1;

    > svg {
      flex-shrink: 0;
    }
  }

  .layout-list-header {
    flex-grow: 4;
    min-width: 0;
  }

  .drive-header {
    gap: 1rem;
    align-items: center;
  }

  &-empty-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    flex-direction: column;
    gap: 1em;
  }

  .active {
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 0;
    color: $textColor;
  }

  .view {
    color: black !important;
    margin-bottom: 0;
  }

  .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
    border-start-start-radius: 0 !important;
  }

  .controls {
    flex-grow: 3;
    flex-shrink: 0;
    &-drive {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      label {
        font-size: 1rem;
      }
      > * {
        flex-shrink: 0;
      }
    }
  }

  .ant-tree.ant-tree-directory .ant-tree-treenode-selected {
    border-right: solid 2px $secondaryColor;
    .ant-tree-title {
      color: $secondaryColor;
    }

    .anticon svg {
      color: $secondaryColor;
    }

    .title-folder {
      color: $secondaryColor;
    }

    .group-icon {
      color: $secondaryColor;
    }

    .DrivesMenu-project-title-icon {
      color: $secondaryColor !important;
    }
  }
}

.wrap-dropdown {
  .ant-dropdown-menu-title-content {
    max-width: 25rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
  }
}

.hidden {
  visibility: hidden;
}

@media (max-width: $md) {
  .Drives {
    &-folder-actions {
      display: none;
    }

    &-folder-actions-mobile {
      display: flex;
    }

    .drive-separator {
      display: none !important;
    }

    .controls {
      flex-grow: 1;
      display: flex;
      justify-content: end;
      gap: 2rem;

      &-drive {
        gap: 1rem;
      }
    }
  }
}

.edit-drive-modal {
  padding: 1rem !important;
}

@media (max-width: $sm) {
  .Drives {
    .controls {
      justify-content: end;
    }

    .tree-mobile-container {
      display: flex;
    }
  }
}

@media (max-width: $lg) {
  .Drives {
    main {
      article {
        margin: 1rem !important;
      }
    }
  }
}

.target-move-drive {
  max-height: 25em !important;
  overflow-y: auto;
}
