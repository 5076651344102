.room-participant-item {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 0.3rem;
  }

  &-body {
    flex-grow: 1;

    &-email svg {
      color: grey;
    }

    &-email-max {
      max-width: 13.125em;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-role {
      color: #888;
    }
  }

  &-actions {
    font-size: 1.3rem;

    svg:nth-child(2) {
      margin-left: 0.5rem;
    }
    &-buttons {
      cursor: pointer;
      transition: transform 0.2s ease-in-out;

      &:hover {
        transform: scale(1.4);
      }
    }
  }
}
