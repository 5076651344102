@import '../../../assets/scss/core/variables';

.devices-configuration-label {
  color: $primaryColorText;
  font-weight: bold;
}

.RoomDevicesConfiguration {
  padding: 1em 0;

  .devices-configuration-video-container {
    text-align: center;
    position: relative;
    max-width: 20rem;
    width: 100%;
    height: 15rem;
    border-radius: 0.4rem;

    .devices-configuration-video,
    .devices-configuration-canvas,
    .devices-configuration-empty-video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scaleX(-1);
      border-radius: 0.4rem;
    }

    .devices-configuration-empty-video {
      background-color: black;
    }

    .devices-configuration-bokehEffectInProgress {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .devices-configuration-webcam-container {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 50%);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.8rem;
      height: 3rem;
      padding: 0.3rem;
      border-radius: 3rem;

      .media-control-container {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-content: center;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0.4rem;
        background: $secondaryColor;
        border-radius: 50%;

        &.control-off {
          background: transparent;
          border: thin solid $primaryColorText;
          background-color: $primaryColor;
        }

        &.control-disabled {
          background: $lightgrey;
          cursor: not-allowed;
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .volume-level-container {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
    }
  }

  .devices-configuration-mediasettings-container {
    min-width: 25rem;
    color: white;
    margin-left: 3em;

    .devices-configuration-mediasettings-row {
    }

    .devices-configuration-mediasettings-title {
      display: flex;
      gap: 1em;
      align-items: baseline;
      margin-bottom: 0.5em;
      font-size: 1.2em;
    }

    .devices-configuration-mediasettings-label {
      display: inline-flex;
      align-items: center;
      margin-bottom: 0.4rem;
    }

    .test-btn {
      width: 2.4rem;
      height: 2.4rem;
      padding: 0.5em !important;
    }

    .stop-btn {
      background-color: red !important;
    }
  }
}

@media (max-width: $lg) {
  .RoomDevicesConfiguration {
    .devices-configuration-mediasettings-container {
      margin-left: 2em;
    }
  }
}

@media (max-width: $md) {
  .RoomDevicesConfiguration {
    .devices-configuration-video-container {
      padding: 0 1rem;
      margin-bottom: 2rem;

      .devices-configuration-video,
      .devices-configuration-canvas {
        max-width: 20rem;
        height: 15rem;
      }
    }
  }
}

// @media (max-width: $sm) {
//   .RoomDevicesConfiguration {
//     .devices-configuration-mediasettings-container {
//       padding: 2em;
//     }
//   }
// }
