@import '../../assets/scss/core/variables';

$zoomColor: rgba(32, 33, 36);
$zoomColorLight: lighten($zoomColor, 5%);

.PeerView {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  z-index: 0;

  .quality-indicator {
    z-index: 1000;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 2em;
  }

  .zoom-buttons {
    position: absolute;
    background-color: $zoomColor;
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.3);
    border-radius: 2.75rem;
    height: 2.75rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s linear;
    z-index: 1001;
    display: flex;
    align-items: center;

    .zoom-icon {
      width: 100%;
      height: 100%;
      padding: 0.4rem;
    }

    .zoom-button {
      color: white;
      cursor: pointer;
      width: 4rem;
      height: 2.75rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        border-radius: 2.75rem;
        background-color: $zoomColorLight;
      }
    }
  }

  &:hover .zoom-buttons {
    opacity: 0.8;
  }

  .display-name {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: left;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 399;
    height: 1.6rem;
    cursor: default;
    background-color: $callGrey;
    border-radius: 0.4rem;
    max-width: 12rem;
    padding: 0.25em 0.5em;
    gap: 0.2em;
    width: fit-content;

    .display-name-text {
      color: white;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 10rem;
    }

    .PeerView-media {
      color: #ff3d3d;
      display: flex;
      align-items: center;
      z-index: 399;
    }
  }

  &-initials {
    //Initials for initial peers
    position: absolute;
    left: calc(50% - 4rem);
    top: calc(50% - 4rem);
    background: #224160;
    border-radius: 50%;
    width: 8rem;
    height: 8rem;
    text-align: center;
    vertical-align: middle;
    line-height: 8rem;
    font-size: 2.8rem;
    color: white;
    z-index: 1;
    text-transform: uppercase;
    font-weight: bold;
  }

  &.active-speaker {
    outline: 1px solid white;
  }

  > video,
  > canvas {
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-user-select: none;
    user-select: none;
    transition-property: opacity;
    transition-duration: 0.5s;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 100;
    overflow: hidden;
    border-radius: 0.5em;

    &.is-webcam-reduced {
      width: 12rem;
      height: 9rem;
      position: absolute;
      right: 5%;
      bottom: 5%;
    }

    &.draggable {
      padding: 0.25rem;
      background-color: white;
      border-radius: 8px;
      box-shadow: 1.5px 1.5px 7px black;

      &:hover {
        box-shadow: 5.5px 5.5px 17px black;
      }
    }

    &.is-my-webcam {
      transform: scaleX(-1);
    }

    &.hidden {
      opacity: 0;
      transition-duration: 0s;
    }

    &.network-error {
      filter: grayscale(100%) brightness(135%) blur(5px);
    }

    &.on-full-screen {
      height: 100vh;
      object-fit: contain;
      margin: 0;
      z-index: 1;
    }

    &.on-full-screen-reduced-cam {
      max-height: 100vh;
      object-fit: contain;
      margin: 0;
      z-index: 1;
    }

    &.Peer--share {
      width: 30vh !important;
    }
  }

  > audio {
    display: none;

    position: absolute;
    top: 0;
    bottom: 0;
    // left: 0;
    right: 0;
    z-index: 1;
    border: 2px solid red;
  }

  > .after-video-container {
    position: relative;

    .volume-container {
      position: absolute;
      left: -10px;
      top: 0;
    }

    > .video-elem-paused {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;
      background-position: center;
      background-image: url('./resources/icon_video_elem_paused.svg');
      background-size: 35%;
      background-color: rgba(#000, 0.25);
      background-repeat: no-repeat;
    }
  }

  .PeerView-filler {
    flex-grow: 1;
    background-color: $primaryColorExtraDarker;
    border-radius: 0.5em;

    &-full-screen {
      height: 100vh;
    }
  }
}
