@import '../../../assets/scss/core/variables';

.chat_create_title_input {
  flex-grow: 1;
}

.create_chat_button {
  width: 2em;
  height: 2em;
  color: $defaultTextColor;
  border-radius: 50%;
}

.create_chatP2P_select {
  width: 100%;
  margin-bottom: 2em;
  margin-top: 1em;
}

.create_chatP2P_label {
  top: 1em;
  position: relative;
}
